.light-theme .example-container {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3) !important;
}

.light-theme .form-control,
.light-theme .asColorPicker-input,
.light-theme .dataTables_wrapper select,
.light-theme .jsgrid .jsgrid-table .jsgrid-filter-row input[type=text],
.light-theme .jsgrid .jsgrid-table .jsgrid-filter-row select,
.light-theme .jsgrid .jsgrid-table .jsgrid-filter-row input[type=number],
.light-theme .select2-container--default .select2-selection--single,
.light-theme .select2-container--default .select2-selection--single .select2-search__field,
.light-theme .typeahead,
.light-theme .tt-query,
.light-theme .tt-hint {
  color: #0b0f24;
  background-color: rgba(0, 0, 0, 0.03);
}

.light-theme .card {
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3) !important;
}

.light-theme app-table-flow table {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3) !important;
}

.light-theme .dropdown-menu {
  background-color: #000000;
}

.light-theme .form-control {
  border: 1px solid rgba(11, 15, 36, 0.3);
  background-color: rgba(11, 15, 36, 0.05);
}

.light-theme .sidebar {
  background: rgba(0, 0, 0, 0.5);
}

.light-theme .navbar .navbar-menu-wrapper {
  background: #000000;
  color: #ffffff;
}

.light-theme .settings-panel {
  background: #000000;
}

.light-theme .settings-panel .nav-tabs {
  background: #000000;
}

.light-theme .dropdown-item:hover,
.light-theme .dropdown-item:focus {
  background-color: rgba(255, 255, 255, 0.3);
}

.light-theme .nav-tabs {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.light-theme .list-wrapper ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: #ffffff;
}

.light-theme .list-wrapper .remove {
  color: #ffffff;
}

.light-theme .sidebar .nav .nav-item.active > .nav-link {
  background: rgba(255, 255, 255, 0.1);
}

.light-theme .sidebar .nav:not(.sub-menu) > .nav-item:hover > .nav-link {
  background: rgba(255, 255, 255, 0.2);
  color: white;
}

.light-theme .sidebar .nav.sub-menu .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.light-theme .sidebar .nav.sub-menu .nav-item .nav-link:hover {
  color: rgba(255, 255, 255, 0.8);
}

.light-theme .sidebar .nav.sub-menu .nav-item .nav-link.active {
  color: #ffffff;
}

.light-theme .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item {
  color: #ffffff;
}

.light-theme .settings-panel .nav-tabs .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}

.light-theme .settings-panel .nav-tabs .nav-item .nav-link.active {
  color: #ffffff;
}

.light-theme .settings-panel .settings-heading {
  color: #ffffff;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.light-theme .content-wrapper {
  background: #ffffff;
}

.light-theme .mat-table {
  background: #ffffff;
}

.light-theme .success-border {
  border-bottom: 10px solid #92d050;
}

.light-theme .success-bg {
  background-color: #92d050;
}

.light-theme .btn-danger {
  /* color: $light-white; */
  background-color: rgba(255, 0, 0, 0.8);
}

.light-theme .btn-danger:hover {
  /* color: $light-white; */
  background-color: #f00;
}

.light-theme .btn-danger:not(:disabled):not(.disabled):active,
.light-theme .btn-danger:not(:disabled):not(.disabled).active,
.light-theme .show > .btn-danger.dropdown-toggle {
  /* color: $light-white; */
  background-color: #f00;
}

.light-theme .error-border {
  border-bottom: 10px solid #f00;
}

.light-theme .error-bg {
  background-color: #f00;
}

.light-theme .warning-border {
  border-bottom: 10px solid #ffff00;
}

.light-theme .warning-bg {
  background-color: #ffff00;
}

.light-theme .btn-primary.disabled,
.light-theme .wizard > .actions a.disabled,
.light-theme .btn-primary:disabled,
.light-theme .wizard > .actions a:disabled {
  color: #0b0f24;
  background-color: #747474;
}

.light-theme .btn-primary,
.light-theme .wizard > .actions a {
  color: #ffffff;
  background-color: rgba(44, 135, 243, 0.8);
}

.light-theme .btn-primary:hover,
.light-theme .wizard > .actions a:hover {
  color: #ffffff;
  background-color: #2c87f3;
}

.light-theme .btn-primary:focus,
.light-theme .wizard > .actions a:focus,
.light-theme .btn-primary.focus,
.light-theme .wizard > .actions a.focus {
  box-shadow: 0 0 0 0.2rem rgba(44, 135, 243, 0.5);
}

.light-theme .btn-primary:not(:disabled):not(.disabled):active,
.light-theme .wizard > .actions a:not(:disabled):not(.disabled):active,
.light-theme .btn-primary:not(:disabled):not(.disabled).active,
.light-theme .wizard > .actions a:not(:disabled):not(.disabled).active,
.light-theme .show > .btn-primary.dropdown-toggle,
.light-theme .wizard > .actions .show > a.dropdown-toggle {
  /* color: $light-white; */
  background-color: #2c87f3;
}

.light-theme .text-blue {
  color: #2c87f3 !important;
}

.light-theme .card:hover {
  background-color: white;
}
.light-theme .card:hover .stretch-card-title i,
.light-theme .card:hover .stretch-card-title h5 {
  text-shadow: 0 0 10px rgba(11, 15, 36, 0.5);
}

.light-theme .mat-expansion-panel {
  background: #ffffff;
  color: #0b0f24;
}

.light-theme .form-control:focus,
.light-theme .asColorPicker-input:focus,
.light-theme .dataTables_wrapper select:focus,
.light-theme .jsgrid .jsgrid-table .jsgrid-filter-row input:focus[type=text],
.light-theme .jsgrid .jsgrid-table .jsgrid-filter-row select:focus,
.light-theme .jsgrid .jsgrid-table .jsgrid-filter-row input:focus[type=number],
.light-theme .select2-container--default .select2-selection--single:focus,
.light-theme .select2-container--default .select2-selection--single .select2-search__field:focus,
.light-theme .typeahead:focus,
.light-theme .tt-query:focus,
.light-theme .tt-hint:focus {
  color: #0b0f24;
  background-color: rgba(11, 15, 36, 0.3);
  border-color: rgba(11, 15, 36, 0.1);
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.light-theme .btn-danger.disabled,
.light-theme .btn-danger:disabled {
  color: #0b0f24;
  background-color: #747474;
}

.light-theme .card-body {
  color: #0b0f24;
}

.light-theme .bg-message-logs {
  color: #0b0f24;
  background-color: #ffffff;
}

.light-theme .highcharts-container text {
  color: #0b0f24 !important;
  fill: #0b0f24 !important;
}

.light-theme .highcharts-tooltip path {
  fill: #ffffff;
}
.light-theme .highcharts-tooltip text {
  fill: #0b0f24 !important;
}

.light-theme .example-container::-webkit-scrollbar-thumb {
  background-color: rgba(11, 15, 36, 0.1) !important;
}

.light-theme mat-select {
  border: 1px solid rgba(11, 15, 36, 0.3);
  background-color: rgba(11, 15, 36, 0.05);
}

.light-theme .mat-table {
  color: #000000;
}
.light-theme .mat-table .mat-header-row {
  background-color: #ffffff;
}
.light-theme .mat-table .mat-row {
  background-color: #ffffff;
}
.light-theme .mat-table .mat-row:nth-child(even) {
  background-color: #cec9c9;
}

.light-theme .mat-paginator .mat-select-value-text,
.light-theme .mat-paginator .mat-select-arrow {
  color: #000000;
}
.light-theme .mat-paginator .mat-paginator-outer-container {
  /* background-color: #1a1635; */
  color: #000000;
}

.light-theme .mat-checkbox-frame {
  border-color: #000000;
}

.light-theme .mat-header-row {
  color: #000000;
}

.light-theme .mat-cell,
.light-theme .mat-header-cell {
  color: #000000;
}

.light-theme .mat-expansion-panel-header-title {
  color: #000000;
}

.light-theme .mat-expansion-indicator::after,
.light-theme .mat-expansion-panel-header-description {
  color: #000000;
}

.light-theme app-table table {
  color: #000000;
  /* background-color: #1a1635; */
}

.light-theme app-table .mat-header-row {
  /* background-color: #312d4e; */
  color: #000000;
}

.light-theme app-table .mat-cell,
.light-theme app-table .mat-header-cell {
  color: #000000;
}

.light-theme .border-top {
  border-top: 1px solid #fff !important;
}

.light-theme .border-right {
  border-right: 1px solid #fff !important;
}

.light-theme .border-bottom {
  border-bottom: 1px solid #fff !important;
}

.light-theme .border-left {
  border-left: 1px solid #fff !important;
}

.light-theme mat-footer-row,
.light-theme mat-header-row,
.light-theme mat-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.light-theme app-translate-language .mat-select-trigger {
  width: 80%;
  float: right;
}

.light-theme app-translate-language mat-select {
  border: 1px solid rgba(255, 255, 255, 0.4);
}